import { calcPercentage } from '../../utils/common_functions';
const initialState = {
  isCreatingNew: false,
  summary: {
    enabledAssignments: {
      percentage: 0,
      value: 0
    },
    disabledAssignments: {
      percentage: 0,
      value: 0
    },
    totalAssignments: 0
  },
  data: {},
  filter: ['This is a fitler'],
}



const assignmentsReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'GET_ASSIGNEMENTS':
      return { ...state, ...getStructuredData(rest) }
    case 'CREATE_ASSIGNEMENT':
      return { ...state, ...rest }
    case 'UPDATE_ASSIGNEMENT':
      return { ...state, ...rest }
    case 'DELETE_ASSIGNEMENT':
      return { ...state, ...rest }
    default:
      return state
  }
}

/**
 * @name getStructuredData
 * Structure the data that is coming from firebase
 * @param  {Array} assignments
 * @returns {Object}
 */
const getStructuredData = ({ assignments }) => {

  // const enabledAssignments = {
  //   percentage: 0,
  //   value: 0
  // };
  // const disabledAssignments = {
  //   percentage: 0,
  //   value: 0
  // };
  // let totalAssignments = assignments ? assignments.length : 0;
  const data = {};

  /**
   * Loop through the list of document and structure the data
   */
  assignments.forEach(doc => {
    // get the data from the document
    const assignment = doc.data();

    if (assignment.status !== 'deleted' && assignment.enabled) {
      // // update the number of pending assignments
      // enabledAssignments.value += assignment.enabled ? 1 : 0;

      // // Update the number of approved assignment by adding one if the status is equal to 'approved
      // disabledAssignments.value += !assignment.enabled ? 1 : 0;

      // Pushed structured data into the data array
      data[assignment.id] = { id: doc.id, ...assignment };

    }

  });
  // enabledAssignments.percentage = calcPercentage(enabledAssignments.value, totalAssignments);
  // disabledAssignments.percentage = calcPercentage(disabledAssignments.value, totalAssignments);
  // return the current state of the data
  return {
    // summary: {
    //   enabledAssignments,
    //   disabledAssignments,
    //   totalAssignments
    // },
    data
  }
};

export default assignmentsReducer