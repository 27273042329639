import uiReducer from './ui.reducers';
import authReducer from './auth.reducer';
import diseasesReducer from './diseases.reducer';
import emegenciesReducer from './emegencies.reducer';
import farmersReducer from './farmers.reducer';
import farmsReducer from './farms.reducer';
import projectsReducer from './projects.reducer';
import subscribersReducer from './subscribers.reducer';
import supervisorsReducer from './supervisors.reducer';
import assignmentsReducer from './assignment.reducer'

import { combineReducers } from "redux";

const reducers = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  diseases: diseasesReducer,
  emegencies: emegenciesReducer,
  farmers: farmersReducer,
  farms: farmsReducer,
  projects: projectsReducer,
  subscribers: subscribersReducer,
  supervisors: supervisorsReducer,
  assignments: assignmentsReducer
});

export default reducers