import { calcPercentage } from '../../utils/common_functions';
const initialState = {
  isCreatingNew: false,
  summary: {
    enabledUsers: {
      percentage: 0,
      value: 0
    },
    disabledUsers: {
      percentage: 0,
      value: 0
    },
    totalUsers: 0
  },
  data: {},
  projectSupervisors: {
    data: {},
    assigned: {
      percentage: 0,
      value: 0
    },
    unAssigned: {
      percentage: 0,
      value: 0
    },
    total: 0
  },
  projectFarmers: {},
  projectFarms: {},
  filter: ['This is a fitler'],
}



const projectsReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'GET_PROJECTS':
      return { ...state, ...getStructuredData(rest) }
    case 'GET_PROJECT_SUPERVISORS':
      console.log(getStructuredProEntity(rest));
      return { ...state, ...getStructuredProEntity(rest) }
    case 'GET_PROJECT_FARMS':
      console.log(getStructuredProEntity(rest));
      return { ...state, ...getStructuredProEntity(rest) }
    case 'GET_PROJECT_FARMERS':
      console.log(getStructuredProEntity(rest));
      return { ...state, ...getStructuredProEntity(rest) }
    default:
      return state
  }
}

/**
 * @name getStructuredData
 * Structure the data that is coming from firebase
 * @param  {Array} projects
 * @returns {Object}
 */
const getStructuredData = ({ projects }) => {

  const enabledUsers = {
    percentage: 0,
    value: 0
  };
  const disabledUsers = {
    percentage: 0,
    value: 0
  };
  let totalUsers = projects ? projects.length : 0;
  const data = {};

  /**
   * Loop through the list of document and structure the data
   */
  projects.forEach(doc => {
    // get the data from the document
    const project = doc.data();

    if (project.status !== 'deleted') {
      // update the number of pending projects
      enabledUsers.value += project.enabled ? 1 : 0;

      // Update the number of approved project by adding one if the status is equal to 'approved
      disabledUsers.value += !project.enabled ? 1 : 0;

      // Pushed structured data into the data array
      data[project.id] = { id: doc.id, ...project };

    }

  });
  enabledUsers.percentage = calcPercentage(enabledUsers.value, totalUsers);
  disabledUsers.percentage = calcPercentage(disabledUsers.value, totalUsers);
  // return the current state of the data
  return {
    summary: {
      enabledUsers,
      disabledUsers,
      totalUsers
    },
    data
  }
};

/**
 * @name getStructuredProSupervisors
 * Structure the data that is coming from firebase
 * @param  {Array} projects
 * @returns {Object}
 */
const getStructuredProEntity = ({ entity, docs }) => {

  const assigned = {
    percentage: 0,
    value: 0
  };
  const unAssigned = {
    percentage: 0,
    value: 0
  };
  let total = docs ? docs.length : 0;
  const data = {};

  /**
   * Loop through the list of document and structure the data
   */
  if (total > 0) {
    docs.forEach(doc => {
      // get the data from the document
      const obj = doc.data();

      if (obj.status !== 'deleted') {
        // update the number of pending obj
        assigned.value += obj.assigned ? 1 : 0;

        // Update the number of approved obj by adding one if the status is equal to 'approved
        unAssigned.value += !obj.assigned ? 1 : 0;

        // Pushed structured data into the data array
        if (data[obj.projectId]) {
          data[obj.projectId].push({ id: doc.id, ...obj });
        } else {
          data[obj.projectId] = [{ id: doc.id, ...obj }];
        }
      }
    });

    assigned.percentage = calcPercentage(assigned.value, total);
    unAssigned.percentage = calcPercentage(unAssigned.value, total);
  }


  // return the current state of the data
  return {
    [entity]: {
      data,
      assigned,
      unAssigned,
      total: 0
    },
  }
};

/**
 * @name getStructuredProFarmers
 * Structure the data that is coming from firebase
 * @param  {Array} projects
 * @returns {Object}
 */
const getStructuredProFarmers = ({ projects }) => {

  const enabledUsers = {
    percentage: 0,
    value: 0
  };
  const disabledUsers = {
    percentage: 0,
    value: 0
  };
  let totalUsers = projects ? projects.length : 0;
  const data = {};

  /**
   * Loop through the list of document and structure the data
   */
  projects.forEach(doc => {
    // get the data from the document
    const project = doc.data();

    if (project.status !== 'deleted') {
      // update the number of pending projects
      enabledUsers.value += project.enabled ? 1 : 0;

      // Update the number of approved project by adding one if the status is equal to 'approved
      disabledUsers.value += !project.enabled ? 1 : 0;

      // Pushed structured data into the data array
      data[project.id] = { id: doc.id, ...project };

    }

  });
  enabledUsers.percentage = calcPercentage(enabledUsers.value, totalUsers);
  disabledUsers.percentage = calcPercentage(disabledUsers.value, totalUsers);
  // return the current state of the data
  return {
    summary: {
      enabledUsers,
      disabledUsers,
      totalUsers
    },
    data
  }
};

/**
 * @name getStructuredProFarms
 * Structure the data that is coming from firebase
 * @param  {Array} projects
 * @returns {Object}
 */
const getStructuredProFarms = ({ projects }) => {

  const enabledUsers = {
    percentage: 0,
    value: 0
  };
  const disabledUsers = {
    percentage: 0,
    value: 0
  };
  let totalUsers = projects ? projects.length : 0;
  const data = {};

  /**
   * Loop through the list of document and structure the data
   */
  projects.forEach(doc => {
    // get the data from the document
    const project = doc.data();

    if (project.status !== 'deleted') {
      // update the number of pending projects
      enabledUsers.value += project.enabled ? 1 : 0;

      // Update the number of approved project by adding one if the status is equal to 'approved
      disabledUsers.value += !project.enabled ? 1 : 0;

      // Pushed structured data into the data array
      data[project.id] = { id: doc.id, ...project };

    }

  });
  enabledUsers.percentage = calcPercentage(enabledUsers.value, totalUsers);
  disabledUsers.percentage = calcPercentage(disabledUsers.value, totalUsers);
  // return the current state of the data
  return {
    summary: {
      enabledUsers,
      disabledUsers,
      totalUsers
    },
    data
  }
};



export default projectsReducer