import { calcPercentage } from '../../utils/common_functions';
const initialState = {
  isCreatingNew: false,
  summary: {
    enabledUsers: {
      percentage: 0,
      value: 0
    },
    disabledUsers: {
      percentage: 0,
      value: 0
    },
    totalUsers: 0
  },
  data: {},
  filter: ['This is a fitler'],
}



const subscribersReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'GET_SUSCRIBERS':
      return { ...state, ...getStructuredData(rest) }
    case 'CREATE_SUSCRIBER':
      return { ...state, ...rest }
    case 'UPDATE_SUSCRIBER':
      return { ...state, ...rest }
    case 'DELETE_SUSCRIBER':
      return { ...state, ...rest }
      case 'DELETE_SUSCRIBER':
        return { ...state, ...rest }
    default:
      return state
  }
}

/**
 * @name getStructuredData
 * Structure the data that is coming from firebase
 * @param  {Array} subscribers
 * @returns {Object}
 */
const getStructuredData = ({ subscribers }) => {

  const enabledUsers = {
    percentage: 0,
    value: 0
  };
  const disabledUsers = {
    percentage: 0,
    value: 0
  };
  let totalUsers = subscribers ? subscribers.length : 0;
  const data = {};

  /**
   * Loop through the list of document and structure the data
   */
  subscribers.forEach(doc => {
    // get the data from the document
    const subscriber = doc.data();

    if (subscriber.status !== 'deleted') {
      // update the number of pending subscribers
      enabledUsers.value += subscriber.enabled ? 1 : 0;

      // Update the number of approved subscriber by adding one if the status is equal to 'approved
      disabledUsers.value += !subscriber.enabled ? 1 : 0;

      // Pushed structured data into the data array
      data[subscriber.id] = { id: doc.id, ...subscriber };

    }

  });
  enabledUsers.percentage = calcPercentage(enabledUsers.value, totalUsers);
  disabledUsers.percentage = calcPercentage(disabledUsers.value, totalUsers);
  // return the current state of the data
  return {
    summary: {
      enabledUsers,
      disabledUsers,
      totalUsers
    },
    data
  }
};

export default subscribersReducer