import React, { useEffect, useRef } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import './scss/style.scss';
import { CToaster } from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux';
import {getSubscribers} from './store/actions/subscribers.action';
import {getProjects,getProjectSupervisors} from './store/actions/projects.action';
import {getSupervisors} from './store/actions/supervisors.action';
import {getFarms} from './store/actions/farms.action';
import {getFarmers} from './store/actions/farmers.action';
import {getAssignments} from './store/actions/assignment.action';
import { useHistory, useParams } from 'react-router-dom';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const toaster = useRef();
    // Create the states of the component
    const dispatch = useDispatch();
    const history = useHistory();
    // const state = useSelector((state) => state.vendors);
    const toast = useSelector((state) => state.ui.toast);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    useEffect(() => {
      dispatch(getSubscribers());
      dispatch(getProjects());
      dispatch(getSupervisors());
      dispatch(getFarms());
      dispatch(getFarmers());
      // dispatch(getAssignments());
      // dispatch(getProjectSupervisors());
      return 0;
    }, []);

    return (
      <>
      <CToaster ref={toaster} push={toast} placement="top-end" />
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
      </>
    );
};

export default App;
