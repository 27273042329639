import React from 'react'
import { collection, addDoc, getDoc, serverTimestamp, doc, setDoc, onSnapshot, updateDoc, writeBatch } from '@firebase/firestore';
import Toast from '../../components/Toast';



const collectionName = 'projects';

export const getProjects = () => {
  return (dispatch, getState, { firestore }) => {
    const collectionRef = collection(firestore, collectionName);
    onSnapshot(collectionRef, (snapshot) => {
      let projects = snapshot.docs;
      dispatch({ type: 'GET_PROJECTS', projects })
    });
  };
}

export const getProjectSupervisors = () => {
  return (dispatch, getState, { firestore }) => {
    const collectionRef = collection(firestore, 'project_supervisors');
    onSnapshot(collectionRef, (snapshot) => {
      let docs = snapshot.docs;
      dispatch({
        type: 'GET_PROJECT_SUPERVISORS',
        docs,
        entity: 'projectSupervisors'
      });
    });
  };
}

export const getProjectFarms = () => {
  return (dispatch, getState, { firestore }) => {
    const collectionRef = collection(firestore, 'project_farms');
    onSnapshot(collectionRef, (snapshot) => {
      let docs = snapshot.docs;
      dispatch({
        type: 'GET_PROJECT_FARMS',
        docs,
        entity: 'projectFarms'
      });
    });
  };
}

export const getProjectFarmers = () => {
  return (dispatch, getState, { firestore }) => {
    const collectionRef = collection(firestore, 'project_farmers');
    onSnapshot(collectionRef, (snapshot) => {
      let docs = snapshot.docs;
      dispatch({
        type: 'GET_PROJECT_FARMERS',
        docs,
        entity: 'projectFarmers'
      });
    });
  };
}

export const updateProjectStatus = (approvalStatus, userId) => {
  return async (dispatch, getState, { firestore }) => {
    const docRef = doc(firestore, collectionName, userId);
    await updateDoc(docRef, { enabled: approvalStatus });
    dispatch({
      type: 'UPDATE_UI',
      toast: (<Toast color='green' title='Task Complete' message='Project Status has been updated' />)
    });
  }
}

export const updateProject = (approvalStatus, id) => {
  return async (dispatch, getState, { firestore }) => {
    const docRef = doc(firestore, collectionName, id);
    await updateDoc(docRef, {
      enabled: approvalStatus
    });
    dispatch({
      type: 'UPDATE_UI',
      toast: (
        <Toast color='green'
          title='Task Complete'
          message='National Admin Status has been updated' />
      )
    });
  }
}

export const addToProject = (collection, docs) => {
  return async (dispatch, getState, { firestore, storage }) => {
    try {
      // Get a new write batch
      const batch = writeBatch(firestore);

      // Set up the batches
      docs.forEach(item => {
        const docRef = doc(firestore, collection, item.id);
        batch.set(docRef, item);
      });

      // Commit the batch
      await batch.commit();

      dispatch({
        type: 'UPDATE_UI',
        toast: (
          <Toast color='green'
            title='Task Complete'
            message='Task completed Successfull'
          />
        )
      });
    } catch (error) {
      console.log(error);
      // dispatch({ type: 'UPDATE_NATIONAL_ADMIN', nationalAdmin });
    }
  }
}

export const addAssignment = (collection, data) => {
  return async (dispatch, getState, { firestore, storage }) => {
    // const farm = data.farm;
    try {
      // Get a new write batch
      await _assignSupervisors(firestore, data, collection);
      await _unAssignSupervisors(firestore, data, collection);

      dispatch({
        type: 'UPDATE_UI',
        toast: (
          <Toast color='green'
            title='Task Complete'
            message='Task completed Successfull'
          />
        )
      });
    } catch (error) {
      console.log(error);
      // dispatch({ type: 'UPDATE_NATIONAL_ADMIN', nationalAdmin });
    }
  }
}

export const removeFromProject = (collection, id) => {
  return async (dispatch, getState, { firestore, storage }) => {
    try {
      const docRef = doc(firestore, collection, id);
      await updateDoc(docRef, {
        status: 'deleted',
        enabled: false,
        state: {
          changeAt: Date.now(),
          reason: ['Deleted by the Admin'],
          status: 'deleted'
        }
      });

      dispatch({ type: 'UPDATE_UI', toast: (<Toast color='red' title='Task Complete' message={`Task completed successfully`} />) });
    } catch (error) {
      console.log(error);
      // dispatch({ type: 'UPDATE_NATIONAL_ADMIN', nationalAdmin });
    }
  }
}

export const addSupervisorsToProject = (supervisors) => {
  return async (dispatch, getState, { firestore, storage }) => {
    try {
      // Get a new write batch
      const batch = writeBatch(firestore);

      // Set up the batches
      supervisors.forEach(item => {
        const docRef = doc(firestore, "project_supervisors", item.id);
        batch.set(docRef, item);
      });

      // Commit the batch
      await batch.commit();

      dispatch({
        type: 'UPDATE_UI',
        toast: (
          <Toast color='green'
            title='Task Complete'
            message='Supervisors Added Successfull'
          />
        )
      });
    } catch (error) {
      console.log(error);
      // dispatch({ type: 'UPDATE_NATIONAL_ADMIN', nationalAdmin });
    }
  }
}

export const removeSupervisorToProject = (id) => {
  return async (dispatch, getState, { firestore, storage }) => {
    try {
      const docRef = doc(firestore, 'project_supervisors', id);
      await updateDoc(docRef, {
        status: 'deleted',
        enabled: false,
        state: {
          changeAt: Date.now(),
          reason: ['Deleted by the Admin'],
          status: 'deleted'
        }
      });
      dispatch({ type: 'UPDATE_UI', toast: (<Toast color='red' title='Task Complete' message={`Suscriber ${id} has been deleted`} />) });
    } catch (error) {
      console.log(error);
      // dispatch({ type: 'UPDATE_NATIONAL_ADMIN', nationalAdmin });
    }
  }
}

/**
 * Create a new National Admin
 * @param  {Object} data
 */
export const createProject = (data) => {
  return async (dispatch, getState, { firestore, storage }) => {
    try {
      // get the collection schema
      const schemaRef = doc(firestore, 'DATABASE_SCHEMA', collectionName);
      const shema = await getDoc(schemaRef).data();
      // create a doc ref for the new admin
      const docRef = doc(firestore, collectionName, ++shema.counter);
      // add the data
      const nationalAdmin = await setDoc(docRef, data);
      // dispatch data
      dispatch({ type: 'CREATE_PROJECT', nationalAdmin });
    } catch (error) {
      console.log(error);
      // dispatch({ type: 'UPDATE_NATIONAL_ADMIN', nationalAdmin });
    }
  }
}

export const deleteProject = (id) => {
  return async (dispatch, getState, { firestore }) => {
    const docRef = doc(firestore, collectionName, id);
    await updateDoc(docRef, {
      status: 'deleted',
      enabled: false,
      state: {
        changeAt: Date.now(),
        reason: ['Deleted by the Admin'],
        status: 'deleted'
      }
    });
    dispatch({ type: 'UPDATE_UI', toast: (<Toast color='green' title='Task Complete' message={`Suscriber ${id} has been deleted`} />) });
  }
}

async function _unAssignSupervisors(firestore, data, collection) {
  const batch = writeBatch(firestore);

  // Set up the batches
  data.unAssignments.forEach(item => {
    // Set the asssignment in the DB
    const docRef = doc(firestore, collection, item.id);
    batch.delete(docRef, item);

    // Get list of all farms and farms assignmment to the supervisor in a project
    let assignments = item.supervisor.assignments ?? [];

    if (item.farmId) {
      let supervisors = item.farm.supervisors ?? [];
      const index = supervisors.indexOf(item.supervisorId);
      supervisors.splice(index, 1);

      const supRef = doc(firestore, 'project_farms', item.projectId + item.farmId);
      batch.update(supRef, { assigned: supervisors.length > 0, supervisors });
      
      const index1 = supervisors.indexOf(item.farmId);
      supervisors.splice(index1, 1);
    }

    if (item.farmerId) {
      let supervisors = item.farmer.supervisors ?? [];
      const index = supervisors.indexOf(item.supervisorId);
      supervisors.splice(index, 1);

      const supRef = doc(firestore, 'project_farmers', item.projectId + item.farmerId);
      batch.update(supRef, { assigned: supervisors.length > 0, supervisors });
      
      const index1 = supervisors.indexOf(item.farmerId);
      supervisors.splice(index1, 1);
    }

    // update project supervisor assignment status
    const supRef = doc(firestore, 'project_supervisors', item.projectId + item.supervisorId);
    batch.update(supRef, { assigned: assignments.length > 0, assignments });

  });

  // Commit the batch
  await batch.commit();
}

async function _assignSupervisors(firestore, data, collection) {
  const batch = writeBatch(firestore);

  // Set up the batches
  data.assignments.forEach(item => {
    // Set the asssignment in the DB
    const docRef = doc(firestore, collection, item.id);
    batch.set(docRef, item);

    // Get list of all farms and farms assignmment to the supervisor in a project
    let assignments = item.supervisor.assignments ?? [];

    if (item.farmId) {
      let supervisors = item.farm.supervisors ?? [];
      supervisors.push(item.supervisorId);
      const supRef = doc(firestore, 'project_farms', item.projectId + item.farmId);
      batch.update(supRef, { assigned: supervisors.length > 0, supervisors });
      assignments.push(item.farmId);
    }

    if (item.farmerId) {
      let supervisors = item.farmer.supervisors ?? [];
      supervisors.push(item.supervisorId);
      const supRef = doc(firestore, 'project_farmers', item.projectId + item.farmerId);
      batch.update(supRef, { assigned: supervisors.length > 0, supervisors });
      assignments.push(item.farmerId);
    }

    // update project supervisor assignment status
    const supRef = doc(firestore, 'project_supervisors', item.projectId + item.supervisorId);
    batch.update(supRef, { assigned: assignments.length > 0, assignments });

  });

  // Commit the batch
  await batch.commit();
}

