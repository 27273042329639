import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyDJiGAJWMKKvJx5ZZ7xaq9Hzl085NmLrbg",
  authDomain: "farmasyst-eaa55.firebaseapp.com",
  projectId: "farmasyst-eaa55",
  storageBucket: "farmasyst-eaa55.appspot.com",
  messagingSenderId: "658974461154",
  appId: "1:658974461154:web:2b1aed4941d4e564dae2bb",
  measurementId: "G-XV8BV36WYB"
};

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(); 
const storage = getStorage();

export {
  firebaseApp,
  firestore,
  storage
}